<template>
  <CLoading :loading="loading" />
  <div class="q-mb-sm" style="margin-top: -50px">
    <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
    <div class="horizontal-line q-mt-sm"></div>
  </div>
  <UserFilters :live-filters="filters" :campaigns="campaigns" @onChangeFilters="changeFilters"></UserFilters>
  <q-table
    bordered
    flat
    dense
    separator="cell"
    v-if="rows"
    :rows="rows"
    v-model:pagination="pagination"
    :rows-per-page-options="rowPerPageOptions"
    :wrap-cells="true"
    row-key="user_id"
    ref="tableRef"
    :no-data-label="$t('messages.no_data_available')"
    class="audience-user text-grey-8"
    @request="onRequest"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th :props="props" key="display_name" style="width: 200px"
          ><span class="text-bold">{{ $t('label.audience_user.display_name') }}</span></q-th
        >
        <q-th :props="props" key="display_name" style="width: 200px"
          ><span class="text-bold">{{ $t('label.register.input_your_email') }}</span></q-th
        >
        <q-th :props="props" key="status_message" style="width: 400px"
          ><span class="text-bold">{{ $t('label.status_message') }}</span></q-th
        >
        <q-th :props="props" key="trigger_type" style="width: 250px"
          ><span class="text-bold">{{ $t('label.story.trigger_type_name') }}</span></q-th
        >
        <q-th :props="props" key="traffic_source_name" style="width: 300px"
          ><span class="text-bold">{{ $t('label.story.traffic_source_name') }}</span></q-th
        >
        <q-th :props="props" key="created_at" style="width: 200px"
          ><span class="text-bold">{{ $t('label.users.signed_up_at') }}</span></q-th
        >
        <q-th :props="props" key="last_registered_at" style="width: 200px"
          ><span class="text-bold">{{ $t('label.users.last_registered_at') }}</span></q-th
        >
        <q-th :props="props" key="updated_at" style="width: 200px"
          ><span class="text-bold">{{ $t('label.updated_at') }}</span></q-th
        >
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="display_name" :props="props">
          <a :href="onProfileUrl(props.row)" class="cursor-pointer hyper-link-chart">
            <UserAvatar :size="24" :user="getUserInfo(props.row.display_name, props.row.picture_url)" />
            <span class="q-ml-sm hyper-link-user">{{ props.row.display_name }}</span>
          </a></q-td
        >
        <q-td key="display_name" :props="props">undefined</q-td>
        <q-td key="status_message" :props="props">{{ props.row.status_message }}</q-td>
        <q-td v-if="props.row.trigger_type">
          {{ $t('label.story.' + props.row.trigger_type + '_trigger') }}
        </q-td>
        <q-td v-else> </q-td>
        <q-td key="traffic_source_name" :props="props">{{ props.row.traffic_source_name }}</q-td>
        <q-td key="created_at" :props="props">{{ props.row.created_at }}</q-td>
        <q-td key="last_registered_at" :props="props">{{ props.row.last_registered_at }}</q-td>
        <q-td key="updated_at" :props="props">{{ props.row.updated_at }}</q-td>
      </q-tr>
    </template>
    <template v-slot:top>
      <h2 class="text-h6 text-black">{{ $t('label.line_users_list') }}</h2>
      <q-space />
    </template>
  </q-table>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ACTION_APP_USER, ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Formater } from '@/utils/formater'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'

import UserAvatar from '@/components/user/UserAvatar.vue'
import { IAppUser, IDateRangePicker, IFilter, IInitialCampaign, IPushCampaign, IUser } from '@/utils/types'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import UserFilters from '@/components/app/UserFilters.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: { UserFilters, CLoading, TestModeSelector, UserAvatar },
  directives: { maska },
})
export default class LineUsers extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  rows = []
  isTestMode = false
  pagination = {
    sortBy: 'updated_at',
    descending: true,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  filters: IFilter[] = []
  pushCampaigns: IPushCampaign[] = []
  initialCampaigns: IInitialCampaign[] = []
  campaigns: {}[] = []

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  getUserInfo(display_name, picture_url) {
    const u: IUser = {
      photo_url: picture_url,
      display_name: display_name,
    }
    return u
  }

  @Watch('isTestMode')
  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.pushCampaigns = await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
      target_setting_ids: ['all'],
    })
    this.pushCampaigns.forEach((element) => {
      this.campaigns.push({
        type: 'push',
        _id: element._id,
        title: element.title,
      })
    })
    this.initialCampaigns = await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
      target_setting_ids: ['all'],
    })
    this.initialCampaigns.forEach((element) => {
      this.campaigns.push({
        type: 'initial',
        _id: element._id,
        title: element.title,
      })
    })

    this.$nextTick(() => {
      // get initial data from server (1st page)
      this.$refs.tableRef.requestServerInteraction()
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loading = true
    const returnedData = await this.fetchFromServer(page, rowsPerPage, filter, sortBy, descending)
    if (returnedData) {
      // eslint-disable-next-line
      const items: [] = returnedData['items']
      this.rows.splice(0, this.rows.length, ...items)
      // eslint-disable-next-line
      this.pagination.rowsNumber = returnedData['total_items']
    }
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending

    this.loading = false
  }

  async fetchFromServer(page, rowsPerPage, filter, sortBy, descending) {
    const items = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        page: page,
        per_page: rowsPerPage,
        filter: filter,
        user_filter: this.filters,
        sort_by: sortBy,
        descending: descending,
      },
    })

    return items
  }

  onProfile(data: IAppUser) {
    this.goto('app_line_user_profile', {
      app_id: this.selectedAppId,
      user_id: data.user_id,
      uid: data._id,
      from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      is_test_mode: this.isTestMode,
    })
  }

  onProfileUrl(data) {
    // Construct the URL as a string for direct navigation
    return this.$router.resolve({
      name: 'app_line_user_profile',
      params: {
        app_id: this.selectedAppId,
        user_id: data.user_id,
        uid: data._id,
        from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
        to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
        is_test_mode: this.isTestMode,
      },
    }).href
  }

  changeFilters(filters) {
    this.filters = filters

    this.$nextTick(() => {
      // get initial data from server (1st page)
      this.$refs.tableRef.requestServerInteraction()
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
.stat-item:not(:last-child) {
  border-radius: 0;
  border-right: 1px solid $separator-color;
}
.user-id-col {
  width: 200px;
}
.is-active-col {
  width: 100px;
}
.audience-user {
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.test-mode {
  margin-left: -8px !important;
}

.q-table--dense .q-table thead tr {
  height: 40px;
}

.q-table--dense .q-table tbody tr,
.q-table--dense .q-table tbody td {
  height: 60px;
}
</style>
